import { combineReducers } from 'redux'
import dashboardReducer from './dashboardReducer'
import dataReducer from './dataReducer'
import filterReducer from './filterReducer'
import filterReducer2 from './filterReducer2'
import authReducer from './authReducer'

const appReducer = combineReducers({
    dashboard: dashboardReducer,
    data: dataReducer,
    filter: filterReducer,
    filter2: filterReducer2,
    auth: authReducer
})

export default appReducer