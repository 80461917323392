import { layerVisConfigChange } from 'kepler.gl/actions'
import { DNCC_BORDER_DATASET, DNCC_POLYLINE_DATASET, DNCC_SITES_DUMMY_DATASET } from '../../App.config'

// Set Point Layer Radius based on Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
    return (dispatch, getState) => {
        // Dispatch Radius Change for each point layer
        const borderThickness = getBorderThicknessWithZoom(zoom)
        const polyLineThickness = getPolyLineThicknessWithZoom(zoom)
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(l.config.dataId ===  DNCC_BORDER_DATASET.DATA_ID && getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, { thickness: borderThickness * 1.5 }) )

            } else if(l.config.dataId ===  DNCC_POLYLINE_DATASET.DATA_ID && getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, { thickness: polyLineThickness }) )

            } else if(l.config.dataId ===  DNCC_SITES_DUMMY_DATASET.DATA_ID && getLayerType(l) === 'point') {
                dispatch( layerVisConfigChange(l, { radius: polyLineThickness * 15 }) )

            } else if(getLayerType(l) === 'point') {
                dispatch( layerVisConfigChange(l, { radius: polyLineThickness * 10 }) )

            } else if(getLayerType(l) === 'icon') {
                dispatch( layerVisConfigChange(l, { radius: polyLineThickness * 3 }) )
            }
        })
    }
}

///////////////
// Utilities //
///////////////

// Get Layer Type
export function getLayerType(curLayer) {
    // If Layer exists
    if(curLayer) {
        if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
            // Point Type
            return 'point'

        } else if(curLayer.getIconAccessor && curLayer.visConfigSettings.radius && !curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
            // Icon Type
            return 'icon'

        } else if(curLayer.config.columns.geojson && curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && curLayer.visConfigSettings.wireframe) {
            // Polygon Type
            return 'geojson'

        } else if(curLayer.visConfigSettings.colorAggregation && !curLayer.visConfigSettings.resolution) {
            // Grid Type
            return 'grid'

        } else if(curLayer.visConfigSettings.colorAggregation && curLayer.visConfigSettings.resolution) {
            // Hexbin Type
            return 'hexagon'

        } else if(curLayer.computeHeatmapConfiguration) {
            // Heatmap Type
            return 'heatmap'

        } else if(curLayer.visConfigSettings.clusterRadius) {
            // Cluster Type
            return 'cluster'

        } else if(curLayer.dataToFeature && curLayer.dataToTimeStamp && curLayer.config.visConfig.trailLength) {
            // Trip Type
            return 'trip'
        }
    }

    return ''
}

// Get Border Thickness With Zoom
function getBorderThicknessWithZoom(zoom) {
    let thickness = 0.5

    if(zoom > 15 && zoom <= 16) {
        thickness = 0.4

    } else if(zoom > 16 && zoom <= 17) {
        thickness = 0.3

    } else if(zoom > 17 && zoom <= 18) {
        thickness = 0.2

    } else if(zoom > 18 && zoom <= 19) {
        thickness = 0.1

    } else if(zoom > 19 && zoom <= 20) {
        thickness = 0.05

    } else if(zoom > 20) {
        thickness = 0.02
    }

    return thickness
}

// Get Poly Line Thickness With Zoom
function getPolyLineThicknessWithZoom(zoom) {
    let thickness = 2

    if(zoom > 15 && zoom <= 16) {
        thickness = 1

    } else if(zoom > 16 && zoom <= 17) {
        thickness = 0.5

    } else if(zoom > 17 && zoom <= 18) {
        thickness = 0.4

    } else if(zoom > 18 && zoom <= 19) {
        thickness = 0.3

    } else if(zoom > 19 && zoom <= 20) {
        thickness = 0.2

    } else if(zoom > 20) {
        thickness = 0.1
    }

    return thickness
}