import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    dateFrom: '',
    dateTill: ''
}

const filterReducer2 = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_DATE_FROM:
            return {
                ...state,
                dateFrom: action.payload.dateFrom
            }
        
        case ActionTypes.SET_DATE_TILL:
            return {
                ...state,
                dateTill: action.payload.dateTill
            }
        
        default:
            return state
    }
}

export default filterReducer2