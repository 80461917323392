import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2ddbac'
        },
        secondary: {
            main: '#303840'
        },
        text: {
            primary: '#ffffff',
            secondary: '#2ddbac'
        }
    }
})

// Theme Props For Components
theme.props = {
    MuiDrawer: {
        variant: 'persistent'
    }
}

// Theme Style Overrides For Components
theme.overrides = {
    MuiDrawer: {
        paper: {
            background: 'transparent',
            border: 'none'
        },
        paperAnchorDockedLeft: {
            border: 'none !important'
        }
    }
}

export default theme