import { KeplerGlSchema } from 'kepler.gl/schemas'
import { mapStyleChange, mapConfigChange, receiveMapConfig, layerVisConfigChange } from 'kepler.gl/actions'
import { getLayerType } from './layerActions'
import { DNCC_BORDER_DATASET, DNCC_POLYLINE_DATASET, DNCC_SITES_DATASET, COLOR_PALETTES } from '../../App.config'

// Import Map Configs
import initial_custom_map_config from '../../configs/initial_custom_map_config.json'

// Load Initial Custom Map with Two Default Styles such as Mapbox Satellite and Barikoi Map Styles
export function loadInitialCustomMap() {
    return dispatch => {
        const initialCustomMapConfig = initial_custom_map_config
        const parsedInitialCustomMapConfig = KeplerGlSchema.parseSavedConfig(initialCustomMapConfig)
        dispatch( receiveMapConfig(parsedInitialCustomMapConfig) )
    }
}

// Set Current Map Style i.e Sattelite, Barikoi One Map
export function setCurrentMapStyle(mapStyleType) {
    return (dispatch, getState) => {
        // Check if already selected
        const { styleType } = getState().keplerGl.map.mapStyle
        if(styleType === mapStyleType) {
            return
        }

        dispatch( mapStyleChange(mapStyleType) )

        // Set Map Visible Layers
        const { visibleLayerGroups } = getState().keplerGl.map.mapStyle
        Object.keys(visibleLayerGroups).forEach(key => {
            if(key !== '3d building') {
                visibleLayerGroups[key] = true
            }
        })

        dispatch( mapConfigChange({ visibleLayerGroups }) )

        // Change layer Colors based on Map Style
        let borderStrokeColor = []
        let newColorRange = []
        if(mapStyleType === 'barikoi_dark' || mapStyleType === 'satellite') {
            borderStrokeColor = [ 246, 186, 0 ]
            newColorRange = COLOR_PALETTES.POLYLINE_DARK

        } else {
            borderStrokeColor = [ 203, 56, 11 ]
            newColorRange = COLOR_PALETTES.POLYLINE_LIGHT
        }

        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            // Border Color
            if(l.config.dataId === DNCC_BORDER_DATASET.DATA_ID && getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, { strokeColor: borderStrokeColor }))
            }

            // LineString Color
            if(l.config.dataId === DNCC_POLYLINE_DATASET.DATA_ID && getLayerType(l) === 'geojson') {
                // Set Layer Color Range
                const strokeColorRange = {
                    category: 'Barikoi',
                    colors: newColorRange,
                    name: 'DNCC Projects',
                    type: 'sequential'
                }

                dispatch( layerVisConfigChange(l, { strokeColorRange }) )
            }

            // Point Color
            if(l.config.dataId === DNCC_SITES_DATASET.DATA_ID && getLayerType(l) === 'point') {
                // Set Layer Color Range
                const colorRange = {
                    category: 'Barikoi',
                    colors: newColorRange,
                    name: 'DNCC Project Sites',
                    type: 'sequential'
                }

                dispatch( layerVisConfigChange(l, { colorRange }) )
            }
        })
    }
}