import * as ActionTypes from './actionTypes'

// Set Date From
export function setDateFrom(dateFrom) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_DATE_FROM, payload: { dateFrom } })
    }
}

// Set Date Till
export function setDateTill(dateTill) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_DATE_TILL, payload: { dateTill } })
    }
}