import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

class StyledTable extends React.PureComponent {
    render() {
        const { headers, rows } = this.props

        return (
                <StyledTableContainer component={ Paper }>
                    <Table size='small' stickyHeader={ true } style={{ tableLayout: 'fixed', whiteSpace: 'nowrap' }}>
                        <TableHead>
                            <TableRow>
                                {
                                    headers.map((header, index) =>
                                        <StyledHeaderTableCell key={ index } align='left' >{ header }</StyledHeaderTableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { rows.map((row, index) => (
                                <TableRow key={ index } id={ index }>
                                    {
                                        row.map((cell, ind) =>
                                            <StyledRowTableCell key={ ind } align='left'>{ cell }</StyledRowTableCell>
                                        )
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
        )
    }
}

// JSS Styles
const StyledTableContainer = withStyles({
    root: {
        background: 'transparent',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        borderLeft: '1px solid rgba(56, 64, 78, 0.8)',
        borderRight: '1px solid rgba(56, 64, 78, 0.8)',
        maxHeight: '240px', 
        tableLayout: 'auto'
    }
})(TableContainer)


const StyledHeaderTableCell = withStyles({
    root: {
        fontSize: '10px',
        fontWeight: '600',
        color: 'white',
        background: 'rgba(56, 64, 78, 0.8)',
        borderBottom: 'none',  
        minWidth: '10px',
        width: '20px',
        maxWidth: '40px',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        overflow: 'hidden',
        wordWrap: 'break-word',
        padding: '4px 0px 4px 16px !important'
    }
})(TableCell)

const StyledRowTableCell = withStyles({
    root: {
        fontSize: '10px',
        color: 'white',
        borderBottom: 'none',
        minWidth: '10px',
        width: '20px',
        maxWidth: '30px',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        overflow: 'hidden',
        wordWrap: 'break-word',
        padding: '4px 0px 4px 16px !important'
    }
})(TableCell)

// Prop Types
StyledTable.propTypes = {
    headres: PropTypes.array,
    rows: PropTypes.array
}

StyledTable.defaultProps = {
    headers: [],
    rows: []
}

export default StyledTable