// Dashboard Action Types
export const SET_IS_DASHBOARD_OPEN = 'SET_IS_DASHBOARD_OPEN'

// Data Action Types
export const SET_IS_DATA_LOADING = 'SET_IS_DATA_LOADING'

// Filter Action Types
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'
export const SET_WARD_OPTIONS = 'SET_WARD_OPTIONS'
export const SET_SELECTED_WARD = 'SET_SELECTED_WARD'

// Filter Action Types 2
export const SET_DATE_FROM = 'SET_DATE_FROM'
export const SET_DATE_TILL = 'SET_DATE_TILL'

// Auth Action Types
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'