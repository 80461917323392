import React from 'react'
import PropTypes from 'prop-types'
import { differenceWith as _differenceWith, isEqual as _isEqual } from 'lodash'
import { Viewer, ImageUrlSource, EquirectGeometry, RectilinearView, autorotate } from 'marzipano'

// Import Components
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import linkHotspotIcon from '../../assets/link.png'
import MapGL from './MapGL'

class Marzipano360Slider extends React.PureComponent {
    state = {
        loading: true,
        viewer: null,
        scenes: [],
        currentSceneIndex: -1,
        fullscreen: false,
        autorotate: false,
        isHotspotsOn: false
    }

    componentDidMount() {
        // Load Marzipano Images
        this._loadMarzipanoImages()
    }

    componentDidUpdate(prevProps, prevState) {
        const { images, currentSiteIndex } = this.props
        const { viewer, isHotspotsOn, fullscreen } = this.state

        // If Images Props Changes OR If isHotspotsOn state changes
        if((prevProps.images.length !== images.length || _differenceWith(prevProps.images, images, _isEqual).length > 0)
        || (prevState.isHotspotsOn !== isHotspotsOn))
        {
            if(viewer) {
                viewer.destroy()
            }
            this._loadMarzipanoImages(prevProps.currentSiteIndex, currentSiteIndex)
        }

        // If fullscreen changes
        if(prevState.fullscreen !== fullscreen) {
            // Set hotspot
            this.setState({ isHotspotsOn: fullscreen })
        }
    }

    componentWillUnmount() {
        // Cleanup
        this._cleanup()
    }

    // Handle Prev
    handlePrev = () => {
        let { scenes, currentSceneIndex } = this.state
        // Check Validity
        if(currentSceneIndex > 0) {
            currentSceneIndex--
            this._switchScene(scenes[currentSceneIndex])
            this.setState({ currentSceneIndex })
        }
    }

    // Handle Next
    handleNext = () => {
        let { scenes, currentSceneIndex } = this.state

        // Check Validity
        if(currentSceneIndex < scenes.length-1) {
            currentSceneIndex++
            this._switchScene(scenes[currentSceneIndex])
            this.setState({ currentSceneIndex })
        }
    }

    // Toggle Fullscreen
    toggleFullScreen = () => {
        const { fullscreen } = this.state

        // Toggle Full Screen
        const marzipano = document.getElementById('marzipano-360')
        if(!fullscreen && marzipano.requestFullscreen) {
            // Enter Full Screen
            marzipano.requestFullscreen()

        } else if(fullscreen && document.exitFullscreen()) {
            // Exit Full Screen
            document.exitFullscreen()
        }
    }

    ///////////////
    // Utilities //
    ///////////////
    // Cleanup
    _cleanup = () => {
        const { viewer } = this.state

        if(viewer) {
            // Remove Listeners
            const stage = viewer.stage()
            if(stage) {
                stage.removeEventListener('renderComplete', this._renderCompleteHandler)
            }

            // Destroy Viewer Context
            if(viewer) {
                viewer.destroy()
            }
        }
    }

    // Load Images with Marzipano
    _loadMarzipanoImages = (prevSiteIndex=0, currentSiteIndex=0) => {
        const { images } = this.props
        const { loading, isHotspotsOn } = this.state

        // Set Loading true
        if(!loading) {
            this.setState({ loading: !loading })
        }

        // Get Marzipano Container
        const panoContainer = document.getElementById('pano-container')

        // Create Viewer
        const viewer = new Viewer(panoContainer)

        // Create Scenes
        const scenes = images.map(i => {
            // Create source
            const source = ImageUrlSource.fromString(i.url)

            // Create geometry
            const geometry = new EquirectGeometry([{ width: 4000 }])

            // Create view
            // const limiter = RectilinearView.limit.traditional(1024, 100*Math.PI/180)
            const limiter = RectilinearView.limit.traditional(1024, 90*Math.PI/180, 180*Math.PI/180)
            const view = new RectilinearView({ yaw: Math.PI }, limiter)

            // Create scene
            const scene = viewer.createScene({ source, geometry, view, pinFirstLevel: true })

            // Create Hotspots
            if(isHotspotsOn && i.hotspots) {
                i.hotspots.forEach(h => {
                    const element = this._createHotspotElement(h)
                    scene.hotspotContainer().createHotspot(element, { yaw: h.yaw, pitch: h.pitch })
                })
            }

            // Set lookTo based on siteIndex
            if(prevSiteIndex !== currentSiteIndex && prevSiteIndex >= 0 && currentSiteIndex >= 0) {
                if(prevSiteIndex < currentSiteIndex) {
                    // Front
                    view.setParameters({ yaw: 180 * Math.PI/180 })

                } else if(prevSiteIndex > currentSiteIndex) {
                    // Back
                    view.setParameters({ yaw: 0 * Math.PI/180 })
                }
            }

            return { source: i, scene }
        })

        // Display scene
        if(scenes.length > 0) {
            this.setState({ viewer, scenes, currentSceneIndex: 0 })

            // Switch Scene
            this._switchScene(scenes[0])

            // Add Scene Rendering finished listeners
            const stage = viewer.stage()
            if(stage) {
                stage.addEventListener('renderComplete', this._renderCompleteHandler)
            }

            // Fullscreen Toggle
            const marzipano = document.getElementById('marzipano-360')
            marzipano.onfullscreenchange = () => {
                const { fullscreen } = this.state
                const { fullscreenElement } = document
                if((!fullscreen && fullscreenElement) || (fullscreen && !fullscreenElement)) {
                    this.setState({ fullscreen: !fullscreen })
                }
            }
        }
    }

    // Handle Render Complete
    _renderCompleteHandler = loaded => {
        const { loading, autorotate } = this.state

        if(loaded && loading) {
            this.setState({ loading: false })

            if(autorotate) {
                // Start Autorotate
                this._stopAutorotate()
                this._startAutorotate()
            }
        }
    }

    // Handle Switch Scene
    _switchScene = scene => {
        const { autorotate } = this.state

        this._stopAutorotate()
        scene.scene.switchTo()

        if(autorotate) {
            this._startAutorotate()
        }
    }

    // Start Autorotate
    _startAutorotate = () => {
        const { viewer } = this.state

        const autorotateConfig = autorotate({
            yawSpeed: 0.05,
            targetPitch: 0,
            targetFov: Math.PI/2
        })

        if(viewer) {
            const scene = viewer.scene()
            if(scene) {
                viewer.startMovement(autorotateConfig)
                viewer.setIdleMovement(5000, autorotateConfig)
            }
        }
    }

    // Stop Autorotate
    _stopAutorotate = () => {
        const { viewer } = this.state

        if(viewer) {
            const scene = viewer.scene()
            if(scene) {
                viewer.stopMovement()
                viewer.setIdleMovement(Infinity, null)
            }
        }
    }

    // Create Hotpot Element
    _createHotspotElement = hotspot => {
        const { toTargetImages } = this.props

        // Create Container
        const container = document.createElement('div')
        container.className = 'hotspot-container'
        container.style.cursor = 'pointer'

        // Create image element
        const icon = document.createElement('img')
        icon.className = 'hotspot-icon'
        icon.style.width = '72px'
        icon.style.height = '72px'
        icon.src = linkHotspotIcon
        icon.alt = 'Hotspot'
        icon.style.opacity = 0.5
        icon.style.transform = 'rotateX(45deg)'

        // Event Listener
        if(toTargetImages) {
            container.onclick = () => {
                toTargetImages(hotspot.targetImagesIndex)
            }
        }

        // Prevent touch and scroll events from reaching the parent element
        // This prevents the view control logic from interfering with the hotspot
        this._stopTouchAndScrollEventPropagation(container)

        container.appendChild(icon)

        return container
    }

    // Prevent touch and scroll events from reaching the parent element.
    _stopTouchAndScrollEventPropagation = element => {
        const eventList = [ 'touchstart', 'touchmove', 'touchend', 'touchcancel', 'pointerdown', 'pointermove', 'pointerup', 'pointercancel', 'wheel' ]
        for(let i = 0; i < eventList.length; i++) {
            element.addEventListener(eventList[i], event => {
                event.stopPropagation()
            })
        }
    }

    // Adjust Current Site Index With Filtered Data
    _adjustCurrentSiteIndex = () => {
        const { sitePointsDataset, currentSiteIndex } = this.props

        // Find First Occurence of selected Project Site index
        const projectIdIndex = sitePointsDataset.fields.findIndex(f => f.name === 'project_id')
        const firstOccurence = sitePointsDataset.allData.findIndex(d => d[ projectIdIndex ] === sitePointsDataset.allData[ currentSiteIndex ][ projectIdIndex ])
        
        return currentSiteIndex-firstOccurence
    }

    render() {
        const { images, borderGeoJson, polylineGeoJson, pointsGeoJson } = this.props
        const { loading, scenes, currentSceneIndex, fullscreen } = this.state

        return (
            <Box id='marzipano-360' { ...marzipano360Styles }>
                <Box id='pano-container' { ...panoContainerStyles } />

                <Box { ...fullScreenToggleStyles } top={ fullscreen ? '8px' : '0px' } right={ fullscreen ? '8px' : '0px' }>
                    <IconButton
                        size={ fullscreen ? 'medium' : 'small' }
                        onClick={ this.toggleFullScreen }
                        style={{ padding: fullscreen ? '4px' : '2px' }}
                    >
                        { fullscreen ?
                            (
                                <FullscreenExitIcon fontSize={ fullscreen ? 'default' : 'small' } style={{ color: '#fff' }} />
                            )
                            :
                            (
                                <FullscreenIcon fontSize={ fullscreen ? 'default' : 'small' } style={{ color: '#fff' }} />
                            )
                        }
                    </IconButton>
                </Box>

                { fullscreen &&
                    <Box { ...pocketContainerStyles }>
                        <MapGL
                            borderGeoJson={ borderGeoJson }
                            polylineGeoJson={ polylineGeoJson }
                            pointsGeoJson={ pointsGeoJson }
                            highlightedPointIndex={ this._adjustCurrentSiteIndex() }
                        />
                    </Box>
                }

                <Box { ...navContainerStyles }>
                    <IconButton
                        size={ fullscreen ? 'medium' : 'small' }
                        disabled={ currentSceneIndex <= 0 ? true : false }
                        onClick={ this.handlePrev }
                        style={{ padding: fullscreen ? '4px' : '2px' }}
                    >
                        <ChevronLeftIcon
                            fontSize={ fullscreen ? 'default' : 'small' }
                            style={{ color: '#fff', opacity: currentSceneIndex <= 0 ? 0.5 : 1.0 }}
                        />
                    </IconButton>

                    <Typography variant='caption' style={{ opacity: 0.8 }}>
                        { currentSceneIndex >= 0 ? new Date(scenes[currentSceneIndex].source.date).toDateString() : 'Date' }
                    </Typography>

                    <IconButton
                        size={ fullscreen ? 'medium' : 'small' }
                        disabled={ currentSceneIndex >= scenes.length-1 ? true : false }
                        onClick={ this.handleNext }
                        style={{ padding: fullscreen ? '4px' : '2px' }}
                    >
                        <ChevronRightIcon
                            fontSize={ fullscreen ? 'default' : 'small' }
                            style={{ color: '#fff', opacity: currentSceneIndex >= scenes.length-1 ? 0.5 : 1.0 }}
                        />
                    </IconButton>
                </Box>

                { (loading && images.length > 0) &&
                    <Box { ...feedbackContainerStyles }>
                        <CircularProgress />
                    </Box>
                }

                { images.length === 0 &&
                    <Box { ...feedbackContainerStyles }>
                        <Typography variant='body1' style={{ opacity: 0.5 }}>{ 'No images found.' }</Typography>
                    </Box>
                }
            </Box>
        )
    }
}

// JSS Styles
const marzipano360Styles = {
    position: 'relative',
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    minWidth: '160px',
    minHeight: '120px',
    overflow: 'auto',
    color: '#fff'
}

const panoContainerStyles = {
    position: 'relative',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%'
}

const feedbackContainerStyles = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const fullScreenToggleStyles = {
    position: 'absolute',
    margin: 0,
    padding: 0,
    bgcolor: 'rgba(0, 0, 0, 0.6)'
}

const navContainerStyles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    maxheight: '48px',
    bgcolor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
}

const pocketContainerStyles = {
    position: 'absolute',
    left: '8px',
    bottom: '40px',
    margin: 0,
    padding: 0,
    width: '25%',
    minWidth: '160px',
    height: '33%',
    overflow: 'hidden'
}

// Prop Types
Marzipano360Slider.propTypes = {
    images: PropTypes.array,
    toTargetImages: PropTypes.func,
    currentSiteIndex: PropTypes.number,
    borderGeoJson: PropTypes.object,
    sitePointsDataset: PropTypes.object
}

Marzipano360Slider.defaultProps = {
    images: [],
    toTargetImages: null,
    currentSiteIndex: 0,
    borderGeoJson: null,
    sitePointsDataset: null
}

export default Marzipano360Slider