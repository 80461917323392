// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'

class Footer extends React.PureComponent {
    render() {
        const { footerTitle } = this.props

        return (
            <Box
                width='100%'
                marginTop='auto'
                borderTop='1px solid rgba(255, 255, 255, 0.5)'
                maxHeight='44px'
                display='flex'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                padding='8px'
                style={{ background: 'rgba(56, 64, 78, 0.8)' }}
            >
                <Typography
                    variant='body2' display='block' align='center' style={ appFooterStylesPB }
                >
                    { 'Developed By |' }
                </Typography>

                <Link href='https://barikoi.com' target='_blank' rel='noopener noreferrer'>
                    <Typography
                        variant='body2' display='block' align='center' style={ appFooterTitleStyles }
                    >
                        { footerTitle }
                    </Typography>
                </Link>
            </Box>
        )
    }
}

// JSS Styles
const appFooterStylesPB = {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '10px',
    fontFamily: 'Rubik, sans-serif',
}


const appFooterTitleStyles = {
    color: '#2ddbac',
    marginLeft: '4px',
    fontSize: '11px',
    fontFamily: 'Rubik, sans-serif',
}

Footer.propTypes = {
    footerTitle: PropTypes.string.isRequired
}

Footer.defaultProps = {
    footerTitle: 'Barikoi Technologies Limited'
}

export default Footer