import axios from 'axios'
import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { removeDataset, addDataToMap, interactionConfigChange, layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, updateMap, reorderLayer } from 'kepler.gl/actions'
import { getLayerType, setLayerSizeWithZoomLevel } from './layerActions'
import { setWardOptions } from './filterActions'
import { getAuthToken } from './authActions'
import { DNCC_BORDER_DATASET, DNCC_POLYLINE_DATASET, DNCC_SITES_DATASET, COLOR_PALETTES, DNCC_SITES_DUMMY_DATASET } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Import Globals & Configs
import dnccPolygon from '../../data/dnccPolygon.json'

// Load DNCC Polygon Hardcoded
export function loadDnccPolygon() {
    return (dispatch, getState) => {
        // Load DNCC Polygon to Map
        const dataInfo = { id: DNCC_BORDER_DATASET.DATA_ID, label: DNCC_BORDER_DATASET.DATA_LABEL }
        const data = processGeojson(dnccPolygon)
        const dataset = { info: dataInfo, data }

        // Options & Configs
        const options = { readOnly: true, centerMap: true }

        // Add Data To Map
        dispatch( addDataToMap({ datasets: dataset, options }) )

        // Set Layer Configs
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, {
                    filled: false,
                    opacity: 0,
                    strokeColor: [ 203, 56, 11 ]
                }))
            }
        })

        // Set Map Center & Zoom
        dispatch( updateMap({ zoom: 13.461058338669261, latitude: 23.882107956703496, longitude: 90.37407069522324 }) )
    }
}

// Load Data to Map
export function loadDataToMap(selectedDate=0) {
    return (dispatch, getState) => {
        // Set `isDataLoading` to true
        dispatch( setIsDataLoading(true) )

        // Prepare Current Date Params
        const today = new Date()
        const dateTill = today.toISOString().slice(0, 10)

        // Set Date to `selectedDate` Days ago
        today.setDate(today.getDate() - selectedDate)
        const dateFrom = today.toISOString().slice(0, 10)

        // Set Date params based on `selectedDate = -1` which denotes `All`
        const params = selectedDate === -1 ? {} : { dateFrom, dateTill }

        // Get Auth Token
        const authToken = getAuthToken()

        // Fetch Data From API
        const polyLineDataset = getState().keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ]
        const apiCalls = polyLineDataset ?
            [ axios.get(DNCC_SITES_DATASET.API_URL, { headers: { Authorization: `Bearer ${ authToken }` }, params }) ]
            :
            [
                axios.get(DNCC_SITES_DATASET.API_URL, { headers: { Authorization: `Bearer ${ authToken }` }, params }),
                axios.get(DNCC_POLYLINE_DATASET.API_URL, { headers: { Authorization: `Bearer ${ authToken }` } })
            ]
        axios.all(apiCalls)
            .then(res => {
                // If Polyline data exists
                if(res.length > 1) {
                    // Build Data
                    const polyLineData = buildPolyLineGeoJSON(res[1].data.projects)

                    // Add Datasets to Map
                    dispatch( loadPolyLineDataToMap(polyLineData) )
                }

                // Remove Sites Dataset
                dispatch( removeDataset(DNCC_SITES_DATASET.DATA_ID) )

                // Build Data
                const sitesData = buildSitesData(res[0].data.projects)

                // Add Datasets to Map
                dispatch( loadSitesDataToMap(sitesData) )
            })
            .catch(err => console.error(err))
    }
}

// Load PolyLine Data To Map
function loadPolyLineDataToMap(polyLineData) {
    return (dispatch, getState) => {
        // Build Dataset
        const dataInfo = { id: DNCC_POLYLINE_DATASET.DATA_ID, label: DNCC_POLYLINE_DATASET.DATA_LABEL }
        const data = processGeojson(polyLineData)
        const dataset = { info: dataInfo, data }

        // Options & Configs
        const options = { readOnly: true, centerMap: false }

        // Add Dataset to Map
        dispatch( addDataToMap({ datasets: dataset, options }) )

        // Set Layer Configs
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson') {
                // Set Layer Label
                dispatch( layerConfigChange(l, { label: 'Projects' }) )

                // Set Layer Color Range
                const strokeColorRange = {
                    category: 'Barikoi',
                    colors: COLOR_PALETTES.POLYLINE_LIGHT,
                    name: 'DNCC Projects',
                    type: 'sequential'
                }

                dispatch( layerVisConfigChange(l, { strokeColorRange }) )

                // Set Color Field By For Dynamic Colors on each project
                const polyLineDataset = getState().keplerGl.map.visState.datasets[ dataInfo.id ]

                if(polyLineDataset) {
                    const projectIdField = polyLineDataset.fields.find(el => el.name === 'id')

                    if(projectIdField) {
                        const newConfig = {
                            strokeColorField: {
                                analyzerType: projectIdField.analyzerType,
                                format: projectIdField.format,
                                id: projectIdField.id,
                                name: projectIdField.name,
                                tableFieldIndex: projectIdField.tableFieldIndex,
                                type: projectIdField.type
                            }
                        }

                        // Dispatch Color Field By
                        dispatch( layerVisualChannelConfigChange(l, newConfig, 'strokeColor') )
                    }
                }

                // Set Layer Size with Zoom Level
                const { zoom }  = getState().keplerGl.map.mapState
                dispatch( setLayerSizeWithZoomLevel(zoom) )
            }
        })

        // Set Tooltip Interaction Config
        const fieldsToShow = DNCC_POLYLINE_DATASET.FIELDS_TO_SHOW.map(i => ({ name: i, format: null }))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ DNCC_POLYLINE_DATASET.DATA_ID ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
    }
}

// Load Sites Data to Map
function loadSitesDataToMap(sitesData) {
    return (dispatch, getState) => {
        // Build Dataset
        const dataInfo = { id: DNCC_SITES_DATASET.DATA_ID, label: DNCC_SITES_DATASET.DATA_LABEL }
        const data = processRowObject(sitesData)
        const dataset = { info: dataInfo, data }

        // Options & Configs
        const options = { readOnly: true, centerMap: false }

        // Add Dataset to Map
        dispatch( addDataToMap({ datasets: dataset, options }) )

        // Set Layer Configs
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(l.config.dataId === dataInfo.id && getLayerType(l) === 'point') {
                // Set Layer Label
                dispatch( layerConfigChange(l, { label: 'Sites' }) )

                // Set Layer Color Range
                const colorRange = {
                    category: 'Barikoi',
                    colors: COLOR_PALETTES.POLYLINE_LIGHT,
                    name: 'DNCC Project Sites',
                    type: 'sequential'
                }

                dispatch( layerVisConfigChange(l, { colorRange }) )

                // Set Color Field By For Dynamic Colors on each project sites
                const sitesDataset = getState().keplerGl.map.visState.datasets[ dataInfo.id ]

                if(sitesDataset) {
                    const projectIdField = sitesDataset.fields.find(el => el.name === 'project_id')

                    if(projectIdField) {
                        const newConfig = {
                            colorField: {
                                analyzerType: projectIdField.analyzerType,
                                format: projectIdField.format,
                                id: projectIdField.id,
                                name: projectIdField.name,
                                tableFieldIndex: projectIdField.tableFieldIndex,
                                type: projectIdField.type
                            }
                        }

                        // Dispatch Color Field By
                        dispatch( layerVisualChannelConfigChange(l, newConfig, 'color') )
                    }
                }

                // Set Layer Size with Zoom Level
                const { zoom }  = getState().keplerGl.map.mapState
                dispatch( setLayerSizeWithZoomLevel(zoom) )
            }
        })

        // Set Tooltip Interaction Config
        const fieldsToShow = DNCC_SITES_DATASET.FIELDS_TO_SHOW.map(i => ({ name: i, format: null }))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ DNCC_SITES_DATASET.DATA_ID ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Set Ward Dropdown List
        const wardList = generateWardList(sitesData)
        dispatch( setWardOptions(wardList) )

        // Set `isDataLoading` to false
        dispatch( setIsDataLoading(false) )
    }
}

// Load Data to Map 2
export function loadDataToMap2(dateFrom, dateTill) {
    return (dispatch, getState) => {
        // Remove Previous Dataset if exists
        dispatch( removeDataset(DNCC_POLYLINE_DATASET.DATA_ID) )
        dispatch( removeDataset(DNCC_SITES_DATASET.DATA_ID) )

        // Set `isDataLoading` to true
        dispatch( setIsDataLoading(true) )

        // Set Date params based on `dateFrom` & `dateTill`
        const params = dateFrom && dateTill ? { dateFrom, dateTill } : {}

        // Get Auth Token
        const authToken = getAuthToken()

        // Fetch Data From API
        axios.all([
            axios.get(DNCC_POLYLINE_DATASET.API_URL, { headers: { Authorization: `Bearer ${ authToken }` } }),
            axios.get(DNCC_SITES_DATASET.API_URL, { headers: { Authorization: `Bearer ${ authToken }` }, params })
        ])
            .then(res => {
                // Build Data
                const polyLineData = buildPolyLineGeoJSON(res[0].data.projects)
                const sitesData = buildSitesData(res[1].data.projects)

                // Add Datasets to Map
                dispatch( loadPolyLineDataToMap(polyLineData) )
                dispatch( loadSitesDataToMap(sitesData) )
            })
            .catch(err => console.error(err))
    }
}

// Set If Data Loading
function setIsDataLoading(isDataLoading) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_DATA_LOADING, payload: { isDataLoading } })
    }
}

// Add Dummy Data for Sites to simulate Hopping Effect
export function addDummySiteData(index, dataId) {
    return (dispatch, getState) => {
        // Remove Existing Dummy Dataset
        dispatch( removeDataset(DNCC_SITES_DUMMY_DATASET.DATA_ID) )

        // Get Dataset
        const targetDataset = getState().keplerGl.map.visState.datasets[ dataId ]

        if(targetDataset && targetDataset.fields && targetDataset.allData) {
            if(index >= 0 && index < targetDataset.allData.length) {
                // Get Fields and Rows
                const fields = []
                let latIndex = -1
                let longIndex = -1

                targetDataset.fields.forEach((f, i) => {
                    if(f.name === 'latitude' && latIndex < 0) {
                        fields.push({
                            analyzerType: f.analyzerType,
                            format: f.format,
                            name: f.name,
                            tableFieldIndex: 1,
                            type: f.type
                        })
                        latIndex = i

                    } else if(f.name === 'longitude' && longIndex < 0) {
                        fields.push({
                            analyzerType: f.analyzerType,
                            format: f.format,
                            name: f.name,
                            tableFieldIndex: 2,
                            type: f.type
                        })
                        longIndex = i
                    }
                })

                const rows = [[ targetDataset.allData[ index ][latIndex], targetDataset.allData[ index ][longIndex] ]]
                
                // Build Dataset
                const dataInfo = { id: DNCC_SITES_DUMMY_DATASET.DATA_ID, label: DNCC_SITES_DUMMY_DATASET.DATA_LABEL }
                const data = { fields, rows }
                const dataset = { info: dataInfo, data }

                // Options & Configs
                const options = { readOnly: true, centerMap: false }

                // Add Dummy Data To Map
                dispatch( addDataToMap({ datasets: dataset, options }) )

                // Layer Configs
                const { layers } = getState().keplerGl.map.visState
                layers.forEach(l => {
                    if(l.config.dataId === dataInfo.id && getLayerType(l) === 'point') {
                        dispatch( layerConfigChange(l, { label: 'Current Position', color: [ 0, 250, 0 ] }))

                        // Set Layer Size with Zoom Level
                        const { zoom }  = getState().keplerGl.map.mapState
                        dispatch( setLayerSizeWithZoomLevel(zoom) )
                    }
                })

                // Set Tooltip Interaction Config
                const fieldsToShow = DNCC_SITES_DUMMY_DATASET.FIELDS_TO_SHOW.map(i => ({ name: i, format: null }))
                const { tooltip } = getState().keplerGl.map.visState.interactionConfig
                tooltip.config.fieldsToShow[ dataInfo.id ] = fieldsToShow
                dispatch( interactionConfigChange(tooltip) )

                // Re-order Layer to Set Dummy Layer on the bottom of main site layer
                dispatch(reorderLayer([ 2, 3, 1, 0 ]))
            }
        }
    }
}

// Remove Dummy Site Data
export function removeDummySiteData() {
    return (dispatch, getState) => {
        // Get Dummy Site Dataset
        const dummySiteDataset = getState().keplerGl.map.visState.datasets[ DNCC_SITES_DUMMY_DATASET.DATA_ID ]

        if(dummySiteDataset) {
            // Remove Dummy Site Dataset
            dispatch( removeDataset(dummySiteDataset.id) )
        }
    }
}

///////////////
// Utilities //
///////////////

// Generate Ward List from Data
function generateWardList(data) {
    let list = []

    data.forEach(i => {
        if(list.length === 0) {
            list.push({ value: i.ward, label: i.ward })
            return
        }

        if(!list.find(el => el.value === i.ward)) {
            list.push({ value: i.ward, label: i.ward })
            return
        }
    })

    list = list.sort((a, b) => {
        const wardNoA = Number(a.label.split('-')[1])
        const wardNoB = Number(b.label.split('-')[1])
        return wardNoA - wardNoB
    })

    return [ { value: 'All', label: 'All' }, ...list ]
}

// Build LineString GeoJSON
function buildPolyLineGeoJSON(projects) {
    const geojson = {
        type: 'FeatureCollection',
        features: projects.map(p => JSON.parse(p.area))
    }

    return geojson
}

function buildSitesData(projects) {
    // Add Hotspots info to sites
    const transformedProjects = addHotspotsToSiteImages(projects)

    // Flatten Sites
    const sites = transformedProjects.map(p => p.sites).flat()

    // Transform project id to string
    sites.forEach(s => {
        s.project_id = `Project-${ s.project_id }`
    })

    return sites
}

// Transform Sites Data to Add Hotpost connections in images
function addHotspotsToSiteImages(projects) {
    let startIndex = 0
    projects.forEach(p => {
        if(p.sites) {
            const sitesLength = p.sites.length

            // Add Hotspots keeping a sequence in mind
            const frontHotspotPosition = { yaw: 180 * Math.PI/180, pitch: 10 * Math.PI/180 }
            const backHotspotPosition = { yaw: 0 * Math.PI/180, pitch: 10 * Math.PI/180 }

            if(sitesLength > 1) {
                p.sites.forEach((s, ind) => {
                    if(s.images) {
                        s.images.forEach(i => {
                            if(ind === 0) {
                                i.hotspots = [
                                    { ...frontHotspotPosition, targetImagesIndex: startIndex+ind+1 }
                                ]

                            } else if(ind === sitesLength-1) {
                                i.hotspots = [
                                    { ...backHotspotPosition, targetImagesIndex: startIndex+ind-1 }
                                ]

                            } else {
                                i.hotspots = [
                                    { ...frontHotspotPosition, targetImagesIndex: startIndex+ind+1 },
                                    { ...backHotspotPosition, targetImagesIndex: startIndex+ind-1 }
                                ]
                            }
                        })
                    }
                })
            }

            // Reset StartIndex
            startIndex = sitesLength
        }
    })

    return projects
}