import { setFilter } from 'kepler.gl/actions'
import { DNCC_SITES_DATASET } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Set Selected Date
export function setSelectedDate(selectedDate) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_SELECTED_DATE, payload: { selectedDate } })
    }
}

// Set Ward Options
export function setWardOptions(wardOptions) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_WARD_OPTIONS, payload: { wardOptions } })
    }
}

// Set Selected Ward
export function setSelectedWard(selectedWard) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_SELECTED_WARD, payload: { selectedWard } })
    }
}

// Filter Data by Selected Ward
export function filterBySelectedWard(selectedWard) {
    return (dispatch, getState) => {
        let filterIndex = getState().keplerGl.map.visState.filters.findIndex(i => i.dataId[0] === DNCC_SITES_DATASET.DATA_ID)
        if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', 'ward') )
            dispatch( setFilter(filterIndex, 'value', selectedWard === 'All' ? [] : [ selectedWard ]) )
        }
    }
}