// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import Typography from '@material-ui/core/Typography'

class MapStyleRadioMenu extends React.PureComponent {
    render() {
        const { selectedMap, handleOnRadioChange } = this.props

        return (
            <FormControl component='fieldset' style={ formControlStyles }>
                <FormLabel style={ formLabelStyles }>{ 'Select Map' }</FormLabel>

                <RadioGroup aria-label='select map' name='select-map' value={ selectedMap } onChange={ handleOnRadioChange }>
                    <FormControlLabel
                        label={ <Typography style={ radioLabelStyles }>{ 'Satellite' }</Typography> }
                        value='satellite'
                        control={
                            <Radio
                                icon={ <RadioButtonUncheckedIcon style={ radioIconStyles } /> }
                                checkedIcon={ <RadioButtonCheckedIcon style={ radioCheckedIconStyles } /> }
                            />
                        }
                        style={ formControlLabelStyles }
                    />
                    <FormControlLabel
                        label={ <Typography style={ radioLabelStyles }>{ 'Barikoi Dark' }</Typography> }
                        value='barikoi_dark'
                        control={
                            <Radio
                                icon={ <RadioButtonUncheckedIcon style={ radioIconStyles } /> }
                                checkedIcon={ <RadioButtonCheckedIcon style={ radioCheckedIconStyles } /> }
                            />
                        }
                        style={ formControlLabelStyles }
                    />
                    <FormControlLabel
                        label={ <Typography style={ radioLabelStyles }>{ 'Barikoi One Map' }</Typography> }
                        value='barikoi_one_map'
                        control={
                            <Radio
                                icon={ <RadioButtonUncheckedIcon style={ radioIconStyles } /> }
                                checkedIcon={ <RadioButtonCheckedIcon style={ radioCheckedIconStyles } /> }
                            />
                        }
                        style={ formControlLabelStyles }
                    />
                    <FormControlLabel
                        label={ <Typography style={ radioLabelStyles }>{ 'Barikoi OSM Liberty' }</Typography> }
                        value='barikoi_osm_liberty'
                        control={
                            <Radio
                                icon={ <RadioButtonUncheckedIcon style={ radioIconStyles } /> }
                                checkedIcon={ <RadioButtonCheckedIcon style={ radioCheckedIconStyles } /> }
                            />
                        }
                        style={ formControlLabelStyles }
                    />
                </RadioGroup>
            </FormControl>
        )
    }
}

// JSS Styles
const formControlStyles = {
    maxwidth: '224px',
    minWidth: '120px',
    maxHeight: '124px',
    background: '#29323C',
    margin: '0px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const formLabelStyles = {
    margin: '0px',
    padding: '8px',
    width: '100%',
    fontFamily: 'ff-clan-web-pro, Helvetica Neue, Helvetica,sans-serif',
    fontSize: '11px',
    color: '#fff',
    textAlign: 'center'
}

const formControlLabelStyles = {
    margin: '0px',
    padding: '0px',
    maxHeight: '20px',
    width: '100%'
}

const radioIconStyles = {
    color: '#fff',
    fontSize: '10px'
}

const radioCheckedIconStyles = {
    color: '#2ddbac',
    fontSize: '10px'
}

const radioLabelStyles = {
    fontFamily: 'ff-clan-web-pro, Helvetica Neue, Helvetica,sans-serif',
    fontSize: '10px',
    color: '#fff',
    whiteSpace: 'nowrap'
}

MapStyleRadioMenu.propTypes = {
    selectedMap: PropTypes.string.isRequired,
    handleOnRadioChange: PropTypes.func.isRequired
}

MapStyleRadioMenu.defaultProps = {
    selectedMap: 'barikoi_dark'
}

export default MapStyleRadioMenu