import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import StyledSelectMenu from '../common/StyledSelectMenu'

// Import Actions & Methods
import { loadDataToMap } from '../../store/actions/dataActions'
import { setSelectedDate, setSelectedWard, filterBySelectedWard } from '../../store/actions/filterActions'

class FilterMenu extends React.PureComponent {
    componentDidMount() {
        const { selectedDate } = this.props

        // Load Initial Data To Map
        this.props.dispatch( loadDataToMap(Number(selectedDate)) )
    }

    componentDidUpdate(prevProps) {
        const { selectedDate, selectedWard } = this.props

        // If Date Changes in dropdown
        if(prevProps.selectedDate !== selectedDate) {
            // Load Selected Date Range Data
            this.props.dispatch( loadDataToMap(Number(selectedDate)) )
        }

        // If Ward Changes in dropdown
        if(prevProps.selectedWard !== selectedWard) {
            // Filter Data By Selected Ward
            this.props.dispatch( filterBySelectedWard(selectedWard) )
        }
    }

    // Handle On Change
    handleOnChange = event => {
        if(event.target.name === 'selectedDate') {
            this.props.dispatch( setSelectedDate(event.target.value) )

        } else if(event.target.name === 'selectedWard') {
            this.props.dispatch( setSelectedWard(event.target.value) )
        }
    }

    render() {
        const { dateOptions, selectedDate, wardOptions, selectedWard } = this.props

        return (
            <Box { ...containerStyles }>
                <Box width='100%'>
                    <StyledSelectMenu
                        menuItems={ dateOptions }
                        label='Select Date'
                        name='selectedDate'
                        value={ selectedDate }
                        onChange={ this.handleOnChange }
                    />
                </Box>

                <Box width='100%' ml='8px'>
                    <StyledSelectMenu
                        menuItems={ wardOptions }
                        label='Select Ward'
                        name='selectedWard'
                        value={ selectedWard }
                        onChange={ this.handleOnChange }
                    />
                </Box>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: '0px',
    padding: '8px 0px 8px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
}

// Prop Types
FilterMenu.propTypes = {
    dateOptions: PropTypes.array,
    selectedDate: PropTypes.string,
    wardOptions: PropTypes.array,
    selectedWard: PropTypes.string
}

FilterMenu.defaultProps = {
    dateOptions: [],
    selectedDate: '',
    wardOptions: [],
    selectedWard: ''
}

const mapStateToProps = state => ({
    dateOptions: state.app.filter.dateOptions,
    selectedDate: state.app.filter.selectedDate,
    wardOptions: state.app.filter.wardOptions,
    selectedWard: state.app.filter.selectedWard
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu)