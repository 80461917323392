import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DNCC_SITES_DATASET } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

class DataCount extends React.PureComponent {
    render() {
        const { count } = this.props

        return (
            <Box { ...containerStyles }>
                <Card variant='outlined' style={{ width: '100%', background: 'none', border: 'none' }}>
                    <CardContent style={{ padding: '8px' }}>
                        <Typography
                            variant='overline'
                        >
                            { 'Total Sites' }
                        </Typography>

                        <Typography
                            display='block'
                            variant='body1'
                            style={{ color: '#2ddbac' }}
                        >
                            { count }
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

// Prop Types
DataCount.propTypes = {
    count: PropTypes.number
}

DataCount.defaultProps = {
    count: 0
}

const mapStateToProps = state => ({
    count: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ DNCC_SITES_DATASET.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ DNCC_SITES_DATASET.DATA_ID ].allData ?
        state.keplerGl.map.visState.datasets[ DNCC_SITES_DATASET.DATA_ID ].allData.length
        : 0
})

export default connect(mapStateToProps, null)(DataCount)