////////////////////////
// App Configurations //
////////////////////////

// Map Config
export const MAP_CONFIG = {
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || '',
    BKOI_API_KEY: process.env.REACT_APP_BKOI_API_KEY || ''
}

// DNCC Border Dataset
export const DNCC_BORDER_DATASET = {
    DATA_ID: 'dncc_border_data',
    DATA_LABEL: 'DNCC Border Data'
}

// DNCC Polyline Dataset
export const DNCC_POLYLINE_DATASET = {
    API_URL: 'https://api.bmapsbd.com/projects/linestring',
    DATA_ID: 'dncc_polyline_data',
    DATA_LABEL: 'DNCC Polyline Data',
    FIELDS_TO_SHOW: [ 'description', 'ward_no', 'start_date', 'end_date', 'progress', 'contractor_name', 'contract_value', 'estimated_cost', 'payable_amount', 'paid_amount', 'egp_id', 'road_length', 'sewer_length', 'footpath_length' ]
}

// DNCC Sites Dataset
export const DNCC_SITES_DATASET = {
    API_URL: 'https://api.bmapsbd.com/projects',
    DATA_ID: 'dncc_sites_data',
    DATA_LABEL: 'DNCC Sites Data',
    FIELDS_TO_SHOW: [ 'name', 'status', 'last_visit_time', 'end_time' ]
}

// DNCC Sites Dummy Dataset
export const DNCC_SITES_DUMMY_DATASET = {
    DATA_ID: 'dncc_sites_dummy_data',
    DATA_LABEL: 'DNCC Sites Data',
    FIELDS_TO_SHOW: []
}

// Authentication Configs
export const AUTH = {
    LOGIN_API: 'https://api.bmapsbd.com/auth/login',
    JWT_VALIDATION_API: 'https://api.bmapsbd.com//auth/user'
}

// Color Palettes
export const COLOR_PALETTES = {
    POLYLINE_DARK: [ '#6dcfdd', '#efc493', '#74aff3', '#a8c280', '#e1b0dd', '#94d5a7', '#bcb8ec', '#d7f0b3', '#a2bfe9', '#bbbc81', '#7cc9ef', '#e5e1b1', '#84e7dd', '#ecaaae', '#bcf0d7', '#cfb592', '#91cfc2', '#b4cb9e', '#80b6aa', '#98c3a6' ],
    POLYLINE_LIGHT: [ '#292b41', '#185933', '#44173a', '#425627', '#403e6c', '#233511', '#71405c', '#233e29', '#6a2f2b', '#0d353f', '#6b4222', '#365367', '#504820', '#091f1b', '#58453e', '#172412', '#3e1d20', '#35584e', '#332712', '#1e3430' ]
}