import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    isDashboardOpen: true
}

const dashboardReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_DASHBOARD_OPEN:
            return {
                ...state,
                isDashboardOpen: action.payload.isDashboardOpen
            }
        
        default:
            return state
    }
}

export default dashboardReducer