import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DNCC_POLYLINE_DATASET } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import FilterMenu from './FilterMenu'
import FilterMenu2 from './FilterMenu2'
import DataCount from './DataCount'
import StyledTable from '../common/StyledTable'
import LogoutButton from '../common/LogoutButton'

class Body extends React.PureComponent {
    //////////////
    // Utilities//
    //////////////
    _generateWardTableRows = () => {
        const { fields, rows } = this.props

        let distinctCounts = []
        let index = -1
        const wardIndex = fields.findIndex(el => el.name === 'ward_no')

        rows.forEach(row => {
            // If not found in distinctCounts, add instance, else increment count
            index = distinctCounts.length === 0 ? -1 : distinctCounts.findIndex(el => el[0] === row[ wardIndex ])
            if(index < 0) {
                distinctCounts.push([ row[ wardIndex ], 1 ])

            } else {
                distinctCounts[ index ][1]++
            }
        })

        // Sort by Ward
        distinctCounts = distinctCounts.sort((a, b) => { return b[1] - a[1] })

        return distinctCounts
    }

    render() {
        return (
            <Box { ...containerStyles }>
                <FilterMenu />
                <FilterMenu2 />
                <DataCount />
                <StyledTable
                    headers={ [ 'Ward', '#Projects' ] }
                    rows={ this._generateWardTableRows() }
                />
                <LogoutButton />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto'
}

// Prop Types
Body.propTypes = {
    fields: PropTypes.array,
    rows: PropTypes.array
}

Body.defaultProps = {
    fields: [],
    rows: []
}

const mapStateToProps = state => ({
    fields: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ].fields ?
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ].fields
        : [],
    rows: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ].allData ?
        state.keplerGl.map.visState.datasets[ DNCC_POLYLINE_DATASET.DATA_ID ].allData
        : []
})

export default connect(mapStateToProps, null)(Body)