import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    dateOptions: [
        { value: '0', label: 'Today' },
        { value: '2', label: 'Past 2 Days' },
        { value: '7', label: 'Past 1 Week' },
        { value: '14', label: 'Past 2 Weeks' },
        { value: '30', label: 'Past 1 Month' },
        { value: '-1', label: 'All' }
    ],
    selectedDate: '0',
    wardOptions: [
        { value: 'All', label: 'All' }
    ],
    selectedWard: 'All'
}

const filterReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload.selectedDate
            }
        
        case ActionTypes.SET_WARD_OPTIONS:
            return {
                ...state,
                wardOptions: action.payload.wardOptions
            }
        
        case ActionTypes.SET_SELECTED_WARD:
            return {
                ...state,
                selectedWard: action.payload.selectedWard
            }
        
        default:
            return state
    }
}

export default filterReducer