import React from 'react'
import PropTypes from 'prop-types'
import { StaticMap } from 'react-map-gl'
import { DeckGL } from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { MAP_CONFIG } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'

class MapGL extends React.PureComponent {
    state = {
        layers: [],
        longitude: 90.3920316696167,
        latitude: 23.873653162245787,
        zoom: 15,
        pitch: 0,
        bearing: 0
    }

    componentDidMount() {
        const { borderGeoJson, polylineGeoJson, pointsGeoJson, highlightedPointIndex } = this.props

        // Create Initial Data Layers
        const layers = this._createDataLayers(borderGeoJson, polylineGeoJson, pointsGeoJson, highlightedPointIndex)

        // Set Map Center
        const center = this._getMapCenter()

        this.setState({ layers, ...center })
    }

    componentDidUpdate(prevProps) {
        const { borderGeoJson, polylineGeoJson, pointsGeoJson, highlightedPointIndex } = this.props

        // If highlightedPointIndex changes in props
        if(prevProps.highlightedPointIndex !== highlightedPointIndex) {
            if(highlightedPointIndex >= 0) {
                // Re-create Data Layers
                const layers = this._createDataLayers(borderGeoJson, polylineGeoJson, pointsGeoJson, highlightedPointIndex)

                // Set Map Center
                const center = this._getMapCenter()

                this.setState({ layers, ...center })
            }
        }
    }

    // Utilities //

    // On ViewState Change Handler
    _onViewStateChangeHandler = newViewState => {
        const { longitude, latitude, zoom, pitch, bearing } = newViewState.viewState
        this.setState({ longitude, latitude, zoom, pitch, bearing })
    }

    // Create All Data Layers
    _createDataLayers = (borderGeoJson, polylineGeoJson, pointsGeoJson, highlightedPointIndex) => {
        const borderLayer = this._createBorderLayer(borderGeoJson)
        const polylineLayer = this._createPolylineLayer(polylineGeoJson)
        const pointsLayer = this._createPointsLayer(pointsGeoJson, highlightedPointIndex)

        return [ borderLayer, polylineLayer, pointsLayer ]
    }

    // Create New Border Layer
    _createBorderLayer = data => {
        return new GeoJsonLayer({
            id: 'border-layer',
            data: data,
            filled: false,
            stroked: true,
            extruded: false,
            wireframe: false,
            lineWidthUnits: 'meters',
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
            lineJointRounded: true,
            lineMiterLimit: 4,
            elevationScale: 1,
            pointRadiusUnits: 'meters',
            pointRadiusScale: 1,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
            material: true,
            getLineColor: [ 203, 56, 11, 200 ],
            getFillColor: [ 203, 56, 11, 200 ],
            getRadius: 10,
            getLineWidth: 1,
            getElevation: 1000,
            pickable: false,
            highlightedObjectIndex: -1,
            highlightColor: [ 255, 255, 0, 255 ]
        })
    }

    // Create New Polyline Layer
    _createPolylineLayer = data => {
        return new GeoJsonLayer({
            id: 'polyline-layer',
            data: data,
            filled: false,
            stroked: true,
            extruded: false,
            wireframe: false,
            lineWidthUnits: 'meters',
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
            lineJointRounded: true,
            lineMiterLimit: 4,
            elevationScale: 1,
            pointRadiusUnits: 'meters',
            pointRadiusScale: 1,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
            material: true,
            getLineColor: [ 0, 255, 0, 255 ],
            getFillColor: [ 0, 255, 0, 255 ],
            getRadius: 10,
            getLineWidth: 6,
            getElevation: 1000,
            pickable: false,
            highlightedObjectIndex: -1,
            highlightColor: [ 255, 255, 0, 255 ]
        })
    }

    // Create New Points Layer
    _createPointsLayer = (data, highlightedPointIndex) => {
        return new GeoJsonLayer({
            id: 'points-layer',
            data: data,
            filled: true,
            stroked: false,
            extruded: false,
            wireframe: false,
            lineWidthUnits: 'meters',
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
            lineJointRounded: true,
            lineMiterLimit: 4,
            elevationScale: 1,
            pointRadiusUnits: 'meters',
            pointRadiusScale: 1,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
            material: true,
            getLineColor: [ 0, 255, 0, 255 ],
            getFillColor: [ 0, 255, 0, 255 ],
            getRadius: 15,
            getLineWidth: 1,
            getElevation: 1000,
            pickable: false,
            highlightedObjectIndex: highlightedPointIndex,
            highlightColor: [ 255, 255, 0, 255 ]
        })
    }

    // Get Map State to fit bounds
    _getMapCenter = () => {
        const { pointsGeoJson, highlightedPointIndex } = this.props

        if(!pointsGeoJson || !pointsGeoJson.features || highlightedPointIndex < 0 || highlightedPointIndex >= pointsGeoJson.features.length || !pointsGeoJson.features[ highlightedPointIndex ] || !pointsGeoJson.features[ highlightedPointIndex ].geometry || pointsGeoJson.features[ highlightedPointIndex ].geometry.type !== 'Point' || !pointsGeoJson.features[ highlightedPointIndex ].geometry.coordinates || pointsGeoJson.features[ highlightedPointIndex ].geometry.coordinates.length < 2) {
            return {}
        }

        const longitude = pointsGeoJson.features[ highlightedPointIndex ].geometry.coordinates[0]
        const latitude = pointsGeoJson.features[ highlightedPointIndex ].geometry.coordinates[1]
        
        return { longitude, latitude }
    }

    render() {
        const { layers, longitude, latitude, zoom, pitch, bearing } = this.state

        return (
            <Box { ...containerStyles }>
                <DeckGL
                    viewState={{ longitude, latitude, zoom, pitch, bearing }}
                    onViewStateChange={ this._onViewStateChangeHandler }
                    controller={ true }
                    layers={ layers }
                >
                    <StaticMap
                        mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                        mapStyle={ `https://map.barikoi.com/styles/barikoi-dark/style.json?key=${ MAP_CONFIG.BKOI_API_KEY }` }
                    />
                </DeckGL>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}

// Prop Types
MapGL.propTypes = {
    borderGeoJson: PropTypes.object,
    polylineGeoJson: PropTypes.object,
    pointsGeoJson: PropTypes.object,
    highlightedPointIndex: PropTypes.number
}

MapGL.defaultProps = {
    borderGeoJson: null,
    polylineGeoJson: null,
    pointsGeoJson: null,
    highlightedPointIndex: -1
}

export default MapGL