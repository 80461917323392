import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import StyledInput from '../common/StyledInput'
import Button from '@material-ui/core/Button'

// Import Actions & Methods
import { setDateFrom, setDateTill } from '../../store/actions/filterActions2'
import { loadDataToMap2 } from '../../store/actions/dataActions'

class FilterMenu2 extends React.PureComponent {
    // Handle On Change
    handleOnChange = event => {
        if(event.target.name === 'dateFrom') {
            this.props.dispatch( setDateFrom(event.target.value) )

        } else if(event.target.name === 'dateTill') {
            this.props.dispatch( setDateTill(event.target.value) )
        }
    }

    // Handle On Submit
    handleOnSubmit = event => {
        event.preventDefault()

        const { dateFrom, dateTill } = this.props

        // Load Data From Date Range
        this.props.dispatch( loadDataToMap2(dateFrom, dateTill) )
    }

    render() {
        const { dateFrom, dateTill } = this.props

        return (
            <Box { ...containerStyles }>
                <form onSubmit={ this.handleOnSubmit } style={ formStyles }>
                    <Box { ...inputContainerStyles }>
                        <Box width='100%'>
                            <StyledInput
                                name='dateFrom'
                                label='From'
                                type='date'
                                size='small'
                                variant='outlined'
                                required={ true }
                                inputProps={{ style: { width: '120px', height: '20px', padding: '8px' } }}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%' }}
                                value={ dateFrom }
                                onChange={ this.handleOnChange }
                            />
                        </Box>

                        <Box width='100%' ml='8px'>
                            <StyledInput
                                name='dateTill'
                                label='To'
                                type='date'
                                size='small'
                                variant='outlined'
                                required={ true }
                                inputProps={{ style: { width: '120px', height: '20px', padding: '8px' } }}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '100%' }}
                                value={ dateTill }
                                onChange={ this.handleOnChange }
                            />
                        </Box>
                    </Box>

                    <Button
                        variant='contained'
                        size='small'
                        type='submit'
                        color='primary'
                        fullWidth={ true }
                        style={{ color: '#fff' }}
                    >
                        { 'Show Data' }
                    </Button>
                </form>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: '0px',
    padding: '8px 0px 8px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const formStyles = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const inputContainerStyles = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
}

// Prop Types
FilterMenu2.propTypes = {
    dateFrom: PropTypes.string,
    dateTill: PropTypes.string
}

FilterMenu2.defaultProps = {
    dateFrom: '',
    dateTill: ''
}

const mapStateToProps = state => ({
    dateFrom: state.app.filter2.dateFrom,
    dateTill: state.app.filter2.dateTill
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu2)