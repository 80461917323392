import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import StyledDrawer from '../common/StyledDrawer'
import Box from '@material-ui/core/Box'
import Header from './Header'
import SubHeader from './SubHeader'
import Footer from './Footer'
import Body from './Body'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Import Actions & Methods
import { setIsDashboardOpen } from '../../store/actions/dashboardActions'

class Dashboard extends React.PureComponent {
    // Handle toggle Dashboard
    openDashboard = () => {
        this.props.dispatch( setIsDashboardOpen(true) )
    }

    closeDashboard = () => {
        this.props.dispatch( setIsDashboardOpen(false) )
    }

    render() {
        const { isDashboardOpen } = this.props

        return (
            <Box>
                { !isDashboardOpen &&
                    <IconButton style={ iconStyles } onClick={ this.openDashboard } >
                        <ChevronRightIcon style={{ fontSize: '20px', color: '#f5f5f5', padding: '6px' }} />
                    </IconButton>
				}

                <StyledDrawer
                    open={ isDashboardOpen }
                    onClose={ this.closeDashboard }
                    variant='persistent'
                >
                    <Box { ...drawerContainerStyles }>
                        <Header closeDashboard={ this.closeDashboard } title={ 'Data Studio' } />
                        <SubHeader />
                        <Body />
                        <Footer footerTitle={ 'Barikoi Technologies Limited' } />
                    </Box>
                </StyledDrawer>
            </Box>
        )
    }
}

// JSS Styles
const drawerContainerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden'
}

const iconStyles = {
    position: 'absolute',
    zIndex: 10,
    left: '12px',
    top: '12px',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    background: 'rgba(56, 64, 78, 0.8)'
}

// Prop Types
Dashboard.propTypes = {
    isDashboardOpen: PropTypes.bool
}

Dashboard.defaultProps = {
    isDashboardOpen: false
}

const mapStateToProps = state => ({ isDashboardOpen: state.app.dashboard.isDashboardOpen })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)