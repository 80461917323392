import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

class SubHeader extends React.PureComponent {
    render() {
        const { isDataLoading } = this.props

        return (
            <React.Fragment>
                <AppBar
                    position='relative'
                    color='transparent'
                >
                    <Box width='100%' margin= '0px' padding= '8px' display='flex' justifyContent='center'>
                        <Typography
                            varaiant='caption'
                            style={{ fontWeight: 300, fontSize: '12px', opacity: '0.5' }}
                        >
                            { 'Dhaka North City Corporation' }
                        </Typography>
                    </Box>
                </AppBar>

                { isDataLoading &&
                    <StyledLinearProgress variant='indeterminate' style={{ width: '100%' }} />   
                }
            </React.Fragment>
        )
    }
}

// Styled Components
const StyledLinearProgress = withStyles({
    root: {
        borderRadius: 4,
        height: '4px'
    },
    colorPrimary: {
        backgroundColor: 'grey'
    },
    bar: {
        borderRadius: 4,
        backgroundColor: '#2ddbac'
    }
})(LinearProgress)

// Prop Types
SubHeader.propTypes = {
    isDataLoading: PropTypes.bool
}

SubHeader.defaultProps = {
    isDataLoading: false
}

const mapStateToProps = state => ({ isDataLoading: state.app.data.isDataLoading })

export default connect(mapStateToProps)(SubHeader)