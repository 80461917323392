import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

class ProjectDetailsModal extends React.PureComponent {
    render() {
        const { open, onClose, keys, values } = this.props

        return (
            <Dialog
                open={ open }
                disableBackdropClick={ true }
                disableEscapeKeyDown={ true }
                PaperProps={{ style: { minWidth: '320px', overflow: 'auto', background: 'rgb(56, 64, 78)' } }}
                fullScreen={ window.innerWidth <= 600 ? true : false }
            >
                <DialogTitle disableTypography={ true } style={{ background: '#29323C', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
                    <Typography variant='h6' style={{ color: '#2ddbac' }}>{ 'Project Details' }</Typography>
                    <IconButton onClick={ onClose } style={ closeIconStyles }>
                        <CloseIcon style={{ color: 'rgba(255, 255, 255, 0.9)' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    { keys.length === 0 && values.length === 0 ?
                        (
                            <Typography variant='body1' style={{ color: 'rgba(255, 255, 255, 0.9)' }}>{ 'No Data Found.' }</Typography>
                        )
                        :
                        (
                            <TableContainer style={{ maxHeight: window.innerWidth <= 600 ? '100%' : '50vh', overflow: 'auto' }}>
                                <Table size='small'>
                                    <TableBody>
                                        { keys.map(( k, i ) => (
                                            <TableRow key={ i }>
                                                <TableCell style={{ ...cellStyles, whiteSpace: 'nowrap' }}><b>{ k }</b></TableCell>
                                                <TableCell style={ cellStyles }>{ values[ i ] }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

// JSS Styles
const closeIconStyles = {
    position: 'absolute',
    top: '8px',
    right: '8px'
}

const cellStyles = {
    border: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    padding: '4px 8px 4px 8px',
    verticalAlign: 'baseline'
}

// Prop Types
ProjectDetailsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    keys: PropTypes.array,
    values: PropTypes.array,
}

ProjectDetailsModal.defaultProps = {
    open: true,
    keys: [],
    values: []
}

export default ProjectDetailsModal