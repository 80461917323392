import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { capitalize as _capitalize } from 'lodash'
import { DNCC_BORDER_DATASET, DNCC_POLYLINE_DATASET, DNCC_SITES_DATASET } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Marzipano360Slider from '../common/Marzipano360Slider'
import ProjectDetailsModal from './ProjectDetailsModal'

// Import Actions & Methods
import { addDummySiteData } from '../../store/actions/dataActions'

class CustomLayerHoverInfo extends React.PureComponent {
    state = {
        placeId: '',
        fieldsToShow: [],
        minTooltipFieldsToShow: [ 'ward_no', 'egp_id', 'progress' ],
        valuesToShow: [],
        layerDataId: '',
        images: [],
        isProjectDetailsModalOpen: false,
        targetSiteIndex: -1
    }

    componentDidMount() {
        let { fields, fieldsToShow, data, layer } = this.props
        let { dataId } = layer.config

        // Set Keys & Values
        const fieldsToShowState = []
        const valuesToShowState = []

        fieldsToShow = fieldsToShow.map(item => item.name)

        fields.forEach((item, index) => {
            if(fieldsToShow.includes(item.name)) {
                if(data[index]) {
                    fieldsToShowState.push(this._transformFieldName(item.name))

                    // Human Readable Date
                    if(item.name.includes('date') || item.name.includes('time')) {
                        valuesToShowState.push(new Date(data[index]).toDateString())
                        
                    } else {
                        valuesToShowState.push(data[index])
                    }
                }
            }
        })

        // Set Images
        const imageIndex = fields.findIndex(f => f.name.includes('image'))
        let images = []

        if(imageIndex >= 0) {
            data[imageIndex].forEach(i => {
                images.push({
                    url: `https://i.postimg.cc/${ i.url }`,
                    date: i.capture_time,
                    hotspots: i.hotspots ? i.hotspots : []
                })
            })

            // Sort images by date recent to old
            images = images.sort((a, b) => {
                const dateA = new Date(a.date)
                const dateB = new Date(b.date)

                return dateB - dateA
            })
        }

        this.setState({
            placeId: data[0],
            fieldsToShow: fieldsToShowState,
            valuesToShow: valuesToShowState,
            layerDataId: dataId,
            images
        })
    }

    componentDidUpdate(prevProps) {
        let { data, fields, fieldsToShow, layer } = this.props
        let { dataId } = layer.config

        // If Data ID Changes in props
        if(prevProps.data[0] !== data[0]) {
            // If data id changes in props
            const fieldsToShowState = []
            const valuesToShowState = []

            fieldsToShow = fieldsToShow.map(item => item.name)

            fields.forEach((item, index) => {
                if(fieldsToShow.includes(item.name)) {
                    if(data[index]) {
                        fieldsToShowState.push(this._transformFieldName(item.name))

                        // Human Readable Date
                        if(item.name.includes('date') || item.name.includes('time')) {
                            valuesToShowState.push(new Date(data[index]).toDateString())

                        } else {
                            valuesToShowState.push(data[index])
                        }
                    }
                }
            })

            // Set Images
            const imageIndex = fields.findIndex(f => f.name.includes('image'))
            let images = []

            if(imageIndex >= 0) {
                data[imageIndex].forEach(i => {
                    images.push({
                        url: `https://i.postimg.cc/${ i.url }`,
                        date: i.capture_time,
                        hotspots: i.hotspots ? i.hotspots : []
                    })
                })

                // Sort images by date recent to old
                images = images.sort((a, b) => {
                    const dateA = new Date(a.date)
                    const dateB = new Date(b.date)

                    return dateB - dateA
                })
            }

            this.setState({
                placeId: data[0],
                fieldsToShow: fieldsToShowState,
                valuesToShow: valuesToShowState,
                layerDataId: dataId,
                images
            })
        }
    }

    // Open Project Details Modal
    openProjectDetailsModal = () => {
        this.setState({ isProjectDetailsModalOpen: true })
    }

    // Close Project Details Modal
    closeProjectDetailsModal = () => {
        this.setState({ isProjectDetailsModalOpen: false })
    }

    // Handle To Target Images
    handleToTargetImages = targetIndex => {
        const { datasets } = this.props
        const { layerDataId } = this.state

        // Set Target Images
        if(datasets && layerDataId && datasets[ layerDataId ] && datasets[ layerDataId ].allData) {
            // Get Target Images from Layer Dataset
            if(targetIndex >= 0 && targetIndex < datasets[ layerDataId ].allData.length) {
                // Get Images Index
                const imagesIndexInData = datasets[ layerDataId ].fields.findIndex(f => f.name === 'images')
                
                if(imagesIndexInData >= 0) {
                    const targetSite = datasets[ layerDataId ].allData[ targetIndex ]

                    if(targetSite && targetSite[ imagesIndexInData ]) {
                        // Set Target Images
                        const targetSiteImages = targetSite[ imagesIndexInData ].map(i => ({
                            url: `https://i.postimg.cc/${ i.url }`,
                            date: i.capture_time,
                            hotspots: i.hotspots ? i.hotspots : []
                        }))
                        this.setState({ images: targetSiteImages, targetSiteIndex: targetIndex })

                        // Jump to Target Site on Map
                        this.props.dispatch( addDummySiteData(targetIndex, layerDataId) )
                    }   
                }
            }
        }
    }

    //////////////
    // Utilities //
    //////////////

    // Transform Field Name
    _transformFieldName = fieldName => {
        return fieldName.split('_').map(f => _capitalize(f)).join(' ')
    }

    // Get DNCC Border GeoJSON Data
    _getDnccBorderGeoJson = () => {
        const { datasets } = this.props

        if(datasets) {
            const dnccBorderDataset = datasets[ DNCC_BORDER_DATASET.DATA_ID ]

            if(dnccBorderDataset) {
                if(dnccBorderDataset.allData && dnccBorderDataset.allData.length > 0) {
                    const dnccBorderGeojson = {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: dnccBorderDataset.allData[0][0].geometry
                            }
                        ]
                    }

                    return dnccBorderGeojson
                }
            }
        }

        return null
    }

    // Get Projects Polyline GeoJSON Data
    _getPolylineGeoJson = () => {
        const { datasets, clicked } = this.props

        if(datasets) {
            const polylineDataset = datasets[ DNCC_POLYLINE_DATASET.DATA_ID ]
            const sitePointsDataset = datasets[ DNCC_SITES_DATASET.DATA_ID ]

            if(polylineDataset && sitePointsDataset) {
                const projectIdIndex = polylineDataset.fields.findIndex(f => f.name === 'id')
                const projectIdIndexInSites = sitePointsDataset.fields.findIndex(f => f.name === 'project_id')

                if(polylineDataset.allData && polylineDataset.allData.length > 0) {
                    const polylineGeojson = {
                        type: 'FeatureCollection',
                        features: polylineDataset.allData.filter(i => i[ projectIdIndex ] === clicked.object.data[ projectIdIndexInSites ]).map(d => ({
                            type: 'Feature',
                            properties: {},
                            geometry: d[0].geometry
                        }))
                    }

                    return polylineGeojson
                }
            }
        }

        return null
    }

    // Get Site Points GeoJSON Data
    _getSitePointsGeoJson = () => {
        const { datasets, clicked } = this.props

        if(datasets) {
            const sitePointsDataset = datasets[ DNCC_SITES_DATASET.DATA_ID ]

            if(sitePointsDataset) {
                const projectIdIndex = sitePointsDataset.fields.findIndex(f => f.name === 'project_id')
                const longIndex = sitePointsDataset.fields.findIndex(f => f.name === 'longitude')
                const latIndex = sitePointsDataset.fields.findIndex(f => f.name === 'latitude')

                if(sitePointsDataset.allData && sitePointsDataset.allData.length > 0 && longIndex >= 0 && latIndex >= 0) {
                    const sitePointsGeojson = {
                        type: 'FeatureCollection',
                        features: sitePointsDataset.allData.filter(i => i[ projectIdIndex ] === clicked.object.data[ projectIdIndex ]).map(d => ({
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Point',
                                coordinates: [ d[ longIndex ], d[ latIndex ] ]
                            }
                        }))
                    }

                    return sitePointsGeojson
                }
            }
        }

        return null
    }

    render() {
        let { layer, clicked, datasets } = this.props
        let { fieldsToShow, minTooltipFieldsToShow, valuesToShow, images, isProjectDetailsModalOpen, targetSiteIndex } = this.state
        let currentSiteIndex = clicked ? clicked.object.index : -1

        return (
            <Box style={ containerStyles }>
                <Box style={ headerStyles }>
                    <Typography variant='subtitle2' style={{ margin: 0, padding: 0 }}><b>{ layer.config.label }</b></Typography>
                </Box>
                
                { fieldsToShow.length > 0 &&
                    <Box style={ bodyStyles }>
                        <TableContainer>
                            <Table size='small' styles={ tableStyles }>
                                { layer.config.dataId === DNCC_POLYLINE_DATASET.DATA_ID ?
                                    (
                                        <TableBody>
                                            { fieldsToShow.map((item, index) =>
                                                minTooltipFieldsToShow.map(i => this._transformFieldName(i)).includes(item) ?
                                                (
                                                    <TableRow key={ index }>
                                                        <TableCell style={{ ...tdStyles, whiteSpace: 'nowrap' }}><b>{ item }</b></TableCell>
                                                        <TableCell style={ tdStyles }>{ valuesToShow[index] }</TableCell>
                                                    </TableRow>
                                                )
                                                :
                                                null
                                            )}
                                        </TableBody>
                                    )
                                    :
                                    (
                                        <TableBody>
                                            { fieldsToShow.map((item, index) =>
                                                <TableRow key={ index }>
                                                    <TableCell style={{ ...tdStyles, whiteSpace: 'nowrap' }}><b>{ item }</b></TableCell>
                                                    <TableCell style={ tdStyles }>{ valuesToShow[index] }</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </TableContainer>

                        { (clicked && clicked.object.data) &&
                            <Box style={ image360ContainerStyles }>
                                <Marzipano360Slider
                                    images={ images }
                                    toTargetImages={ this.handleToTargetImages }
                                    currentSiteIndex={ targetSiteIndex >= 0 ? targetSiteIndex : currentSiteIndex }
                                    borderGeoJson={ this._getDnccBorderGeoJson() }
                                    polylineGeoJson={ this._getPolylineGeoJson() }
                                    pointsGeoJson={ this._getSitePointsGeoJson() }
                                    sitePointsDataset={ datasets[ DNCC_SITES_DATASET.DATA_ID ] }
                                />
                            </Box>
                        }

                        { (clicked && !clicked.object.data && layer.config.dataId === DNCC_POLYLINE_DATASET.DATA_ID) &&
                            <Box style={{ marginLeft: 'auto', marginTop: 'auto' }}>
                                <Button
                                    variant='text'
                                    size='small'
                                    onClick={ this.openProjectDetailsModal }
                                    style={{ textTransform: 'none', color: '#1FBAD6' }}
                                >
                                    { 'Show More...' }
                                </Button>
                            </Box>
                        }
                        
                        <ProjectDetailsModal
                            open={ isProjectDetailsModalOpen }
                            onClose={ this.closeProjectDetailsModal }
                            keys={ fieldsToShow }
                            values={ valuesToShow }
                        />
                    </Box>
                }
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    boxSizing: 'border-box',
    margin: 0,
    padding: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

const headerStyles = {
    boxSizing: 'border-box',
    position: 'relative'
}

const bodyStyles = {
    boxSizing: 'border-box',
    position: 'relative',
    margin: 0,
    padding: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

const tableStyles = {
    boxSizing: 'border-box',
    margin: 0,
    padding: '8px',
    width: '100%'
}

const tdStyles = {
    border: 'none',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '12px',
    verticalAlign: 'baseline'
}

const image360ContainerStyles = {
    boxSizing: 'border-box',
    margin: 0,
    padding: '0px 32px',
    width: '100%',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

// Prop Types
CustomLayerHoverInfo.propTypes = {
    clicked: PropTypes.object,
    datasets: PropTypes.object
}

CustomLayerHoverInfo.defaultProps = {
    clicked: null,
    datasets: null
}

const mapStateToProps = state => ({
    clicked: state.keplerGl.map ? state.keplerGl.map.visState.clicked : null,
    datasets: state.keplerGl.map ? state.keplerGl.map.visState.datasets : null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayerHoverInfo)